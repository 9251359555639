<template>
  <div>
    <iframe
      :src="frame"
      width="100%"
      height="1200px"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { jsPDF } from 'jspdf'
import { useRouter } from '@core/utils'
import prints from '@/api/prints'
import { i18n } from '@/plugins/i18n'
import font from '@/assets/fonts/font.json'
import fontBold from '@/assets/fonts/fontBold.json'
import { formatPrice, sumdate, sumdatetime } from '@/plugins/filters'
import 'jspdf-autotable'

export default {
  setup() {
    const { route } = useRouter()
    const fonts = ref(font)
    const fontsBold = ref(fontBold)
    const dataTableList = ref([])
    const fontTitle = ref(14)
    const fontBody = ref(13)
    const frame = ref('')

    i18n.locale = route.value.query.lang == '2' ? 'en' : 'th'
    prints.printsResultLab({
      opd_id: route.value.query.id.split(','),
      lang: i18n.locale,
    }).then(res => {
      savePDF(res)
    })
    const headers = [
      `${i18n.t('order_no')} ${i18n.t('examination_list')}`,
      i18n.t('detectable_value'),
      i18n.t('reference_value'),
      i18n.t('previous_results'),
    ]

    const generateData = () => {
      const result = []
      dataTableList.value.forEach((sub_item, x) => {
        result.push([`${x + 1}. ${sub_item.opdchecklist_name.replaceAll('\t', '')}`, '', sub_item.opdchecklist_ref, sub_item.opdchecklist_value_last])
        if (sub_item.opdchecklist_set) {
          sub_item.opdchecklist_set.forEach((sub_item2, y) => {
            result.push(
              [`    ${x + 1}.${y + 1}. ${sub_item2.opdchecklist_name.replaceAll('\t', '')}`, '', sub_item2.opdchecklist_ref, sub_item2.opdchecklist_value_last],
            )
          })
        }
      })

      return result
    }

    // const generateData = () => {
    //   const result = []
    //   dataTableList.value.forEach(item => {
    //     result.push([
    //       item.opdchecklist_name,
    //       item.opdchecklist_value,
    //       item.opdchecklist_ref,
    //       item.opdchecklist_value_last,
    //     ])
    //   })

    //   result.map((item, index) => item[0] = `${index + 1}) ${item[0]}`)

    //   return result
    // }
    const savePDF = data => {
      const doc = new jsPDF({
        orientation: 'portrait',
        format: 'a4',
      })
      doc.setProperties({
        title: `ใบส่งตรวจแล็บ - ${data[0].opd_code} - ${data[0].opd_date}`,
      })
      doc.addFileToVFS('TH-Niramit-AS-normal.ttf', fonts.value.data)
      doc.addFileToVFS('TH-Niramit-AS-Bold-bold.ttf', fontsBold.value.data)
      doc.addFont('TH-Niramit-AS-normal.ttf', 'TH-Niramit', 'normal')
      doc.addFont('TH-Niramit-AS-Bold-bold.ttf', 'TH-Niramit', 'bold')
      doc.setFont('TH-Niramit', 'bold')
      const spacer = 5
      let total = 10

      for (let i = 0; i < data.length; i++) {
        dataTableList.value = data[i].check_list
        doc.addImage(JSON.parse(localStorage.getItem('shopImage')), 'JPEG', 7, 6, 28, 26)

        // Start Address Shop block\
        doc.setFontSize(fontTitle.value)
        doc.setFillColor('#212121')
        doc.text(`${data[i].shop_name} ${data[i].shop_nature} ${i18n.t('numberLicenses')} ${data[i].shop_license}`, 40, total)
        doc.setFont('TH-Niramit', 'normal')
        doc.setFontSize(fontBody.value)
        total += spacer
        doc.text(`${data[i].shop_address} ${data[i].shop_district}`, 40, total)
        total += spacer
        doc.setFontSize(16)
        doc.text(`${i18n.t('lab_check_list')}`, 205, total - 5, null, null, 'right')
        doc.text(`${i18n.t('a_number')} ${data[i].opd_code}`, 205, total + 2, null, null, 'right')
        doc.text(`${i18n.t('print')}${i18n.t('date')} ${sumdate(data[i].print_date)}`, 205, total + 8, null, null, 'right')
        doc.setFontSize(fontBody.value)
        doc.text(`${data[i].shop_amphoe} ${data[i].shop_province} ${data[i].shop_zipcode}`, 40, total)
        total += spacer
        doc.text(`${i18n.t('tel')} ${data[i].shop_tel} ${i18n.t('email')} ${data[i].shop_email}`, 40, total)
        total += spacer
        doc.text(`${i18n.t('numberLicenses')} ${data[i].shop_license} ${i18n.t('tax_id')} ${data[i].shop_tax}`, 40, total)
        total += spacer
        doc.setLineWidth(0.8)
        doc.setDrawColor('#1E88E5')
        doc.line(61, 34, 205, 34)
        doc.setDrawColor('#212121')
        doc.line(4, 34, 60, 34)
        total += spacer
        doc.setFontSize(fontTitle.value)
        doc.text(`HN : ${data[i].customer_id}`, 5, total)
        total += spacer
        doc.text(`${i18n.t('name')} ${data[i].customer_fullname} ${i18n.t('gender')} ${data[i].customer_gender} ${i18n.t('age')} ${data[i].customer_age} ${i18n.t('blood_type')} ${data[i].customer_blood}`, 5, total)
        total += spacer
        doc.text(`${data[i].customer_address}`, 5, total)
        total += spacer
        doc.text(`${i18n.t('congenital_disease')} ${data[i].customer_disease} ${i18n.t('be_allergic')} ${data[i].customer_allergic}`, 5, total)
        total += spacer * 2
        doc.setFont('TH-Niramit', 'bold')
        doc.setFontSize(fontTitle.value)
        doc.text(`${i18n.t('laboratory_report')}`, 5, total)
        doc.setFont('TH-Niramit', 'normal')
        doc.setFontSize(fontBody.value)
        doc.text(`${i18n.t('date_of_visit')} : ${sumdate(data[i].opd_date)}`, 205, total, null, null, 'right')

        doc.autoTable({
          theme: 'grid',
          styles: {
            font: 'TH-Niramit',
            fontStyle: 'normal',
            textColor: '#212121',
            fontSize: fontBody.value,
            halign: 'left',
          },
          showFoot: 'lastPage',
          headStyles: {
            textColor: '#fff',
            fillColor: '#1E88E5',
            fontStyle: 'bold',
            lineWidth: 0.1,
            fontSize: fontTitle.value,
            halign: 'center',
          },
          columnStyles: {
            1: { halign: 'center', fillColor: '#F5F5F5', cellWidth: 40 },
            2: { halign: 'center', cellWidth: 40 },
            3: { halign: 'center', fillColor: '#F5F5F5', cellWidth: 40 },
          },
          startY: 67,
          margin: { left: 5, right: 5, bottom: 10 },

          head: [headers],
          body: generateData(),

        })
      }

      frame.value = doc.output('datauristring', { filename: 'test.pdf' })
    }

    return { savePDF, frame }
  },
}
</script>
